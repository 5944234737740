.app {
  font-family: var(--font-base);
  transition: 2s;
}

.whitebg {
  background-color: var(--white-color);
}

.primarybg {
  background-color: var(--primary-color);
}

.container {
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column-reverse;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper {
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 4rem 2rem;

  @media screen and (max-width: 420px) {
    padding: 4rem 1rem 2rem;
  }
}

.copyright {
  width: 100%;
  padding: 1rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);

  p {
    text-transform: uppercase;
    color: var(--black-color);
  }
}

.head-text {
  font-size: 2.75rem;
  font-weight: 800;
  text-align: center;
  color: var(--black-color);
  text-transform: capitalize;

  span {
    color: var(--secondary-color);
  }

  @media screen and (min-width: 2000px) {
    font-size: 4rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 2rem;
  }
}

.p-text {
  font-size: 0.8rem;
  text-align: left;
  color: var(--gray-color);
  line-height: 1.5;
  list-style-type: none;

  @media screen and (min-width: 2000px) {
    font-size: 1.75rem;
  }
}

.bold-text {
  font-size: 1rem;
  font-weight: 800;
  color: var(--black-color);
  text-align: left;

  @media screen and (min-width: 2000px) {
    font-size: 2rem;
  }

  @media screen and (max-width: 420px) {
    font-size: 0.9rem;
  }
}

.social {
  position: fixed;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  padding: 1rem;

  div {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 0.25rem 0;
    border: 1px solid var(--lightGray-color);

    display: flex;
    justify-content: center;
    align-items: center;

    transition: all 0.4s ease-in-out;

    svg {
      width: 21px;
      height: 21px;
      color: var(--gray-color);
    }

    &:hover {
      background-color: var(--secondary-color);
      border-color: var(--secondary-color);

      svg {
        color: var(--white-color);
      }
    }

    @media screen and (min-width: 2000px) {
      width: 70px;
      height: 70px;

      margin: 0.5rem 0;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
}

.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 15px;
  width: 2rem;

  padding: 1rem;

  .navigation-dot {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #070404;
    margin: 0.5rem;

    transition: background-color 0.4s ease-in-out;

    &:hover {
      background-color: var(--secondary-color);
    }

    @media screen and (min-width: 2000px) {
      width: 20px;
      height: 20px;
    }
  }
}

// @media screen and (max-width: 420px) {
//   .navigation,
//   .social {
//     display: none;
//   }

//   .copyright {
//     padding: 2rem;
//   }
// }
