.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  background: var(--primary-color);
  border: 1px solid rgba(255, 255, 255, 0.15);
  position: fixed;
  top: 0;
  z-index: 2;

  .navbar__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 24rem;
    height: 15rem;
    mask-size: contain;
    margin: -90px;
    z-index: 1;

    background: url("../../public/bg.png");
    background-size: 325px;

    -webkit-mask-image: url("../../public/meulogo.png");
    mask-image: url("../../public/meulogo.png");
    mask-repeat: no-repeat;

    @media screen and (max-width: 400px) {
      width: 20rem;
      height: 12rem;
      margin: -75px;
    }
  }

  .navbar__links {
    display: flex;
    flex: 1;
    justify-content: center;
    list-style-type: none;
    z-index: 2;

    li {
      margin: 0 1rem;
      cursor: pointer;

      flex-direction: column;

      div {
        width: 5px;
        height: 5px;
        background: transparent;
        border-radius: 50%;
        margin-bottom: 5px;
        margin-inline: auto;
      }

      a {
        color: var(--gray-color);
        text-decoration: none;
        flex-direction: column;
        text-transform: uppercase;
        font-weight: 500;
        transition: all 0.4s ease-in-out;

        &:hover {
          color: var(--secondary-color);
        }
      }

      &:hover {
        div {
          background: var(--secondary-color);
        }
      }
    }
    @media screen and (max-width: 900px) {
      display: none;
    }
  }
}
.navbar__menu {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary-color);

  div {
    position: fixed;
    top: 4rem;
    bottom: 0;
    right: 0;
    z-index: 5;
    padding: 1rem;
    width: 60%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    background-color: var(--primary-color);
  }
  ul {
    list-style: none;
    padding: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;

    li {
      margin: 0.7rem;
      a {
        color: var(--gray-color);
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: var(--secondary-color);
        }
      }
    }
    @media screen and (min-width: 900px) {
      display: none;
    }
  }
  @media screen and (min-width: 900px) {
    display: none;
  }
}
