.head-text {
  justify-content: center;
  display: flex;
  p {
    margin: 0;
  }
}

.about {
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    justify-content: center;
    align-items: center;
  }
}

.profiles {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 2rem;

  .about-item {
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background-color: var(--primary-color);
    color: #000;
    transition: all 0.4s ease;
    max-width: 380px;
  }

  @media screen and (min-width: 1200px) {
    margin: 2rem 4rem;

    img {
      max-width: 380px;
    }
  }
}

.profile-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  img {
    width: 100%;
    border-radius: 15px;
    object-fit: cover;
  }

  @media screen and (min-width: 1200px) {
    width: 470px;
    margin: 2rem 4rem;

    img {
      width: 100%;
    }
  }

  @media screen and (min-width: 2000px) {
    width: 550px;
    margin: 2rem 4rem;

    img {
      width: 100%;
    }
  }

  .work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;

    h4 {
      margin-top: 1rem;
      line-height: 1.5;
    }
  }
}
